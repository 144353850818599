 .slick-slide img,.slick-slide iframe {
  width: 70vmin; /* �ύX */
  height:auto; /* �ύX */
  margin:0 auto;
}

.slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.overflow{
 width:90%;
 align:center;
 white-space: nowrap;  
 overflow: hidden;  
 text-overflow: ellipsis;  
 -webkit-text-overflow: ellipsis;  
 -o-text-overflow: ellipsis;  
}